
.container {
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow-y: scroll;
    padding: 0px 2rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    gap: 0.5rem;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    scrollbar-width: none;
    opacity: 0;
    height: calc(100%);
    margin-top: 0px;
    transition: margin-top 1s;
}

.horizontal-flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin-top: 60px;
    align-self: center;
}

.mobile-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.default {
    margin-top: calc(50dvh - 185px);
    height: 0px;
    overflow: visible;
    border-top: 20px;
}

.default-mobile {
    margin-top: calc(50dvh - 300px);
    height: 0px;
    overflow: visible;
    border-top: 20px;
}

.main-buttons-container {
    position: relative;
    gap: 0.3rem;
    display:  flex;
    white-space: nowrap; 
    flex-direction: column;  
    scrollbar-width: none;
    width: 23rem;
    z-index: 200;
    margin: 0.5rem 0rem;
}

.main-buttons-container-mobile {
   margin-top: 10px;
}

.main-button {
    all: unset;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0.2rem 0.5rem;
    min-width: 22rem;
    height: 2.1rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 1.25rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    justify-content: center;
    gap: 0.2rem;
}

.main-button.active {
    background: linear-gradient(90deg, rgba(77, 115, 52, 1), rgba(255, 255, 255, 0.1));
}

.no-background {
    background: none;
    padding-left: 0px;
    padding-right: 0px;
}

.main-button.disabled {
    cursor: not-allowed;
}

.main-button:hover:not(.disabled):not(.no-background) {
    background: linear-gradient(90deg, rgba(77, 115, 52, 1), rgba(255, 255, 255, 0.1));
}


.talos-badges-logo {
    width: 16rem;
    height: 16rem;
    box-shadow: 0 30px 40px rgba(0,0,0,0.75);
}


.verified-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
    font-size: 0.9rem;
}

.verified-line p {
    all: unset;
    color: white;
    font-style: italic;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 1);
}

.disabled-option {
    cursor: not-allowed;
    opacity: 0.5;
}

div[class$="singleValue"] { 
    height: 1.3rem;
    overflow: hidden;
}

div[class$="singleValue"] .hide-on-selected { 
    display: none;
}


.twenty-rem-width { 
    width: 20rem;
    overflow: hidden;
    text-overflow: ellipsis;
    
}

.loading-full-size-container {
    position: absolute;
    bottom: 0.6rem;
    left: 0.3rem;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    font-size: 0.7em;
}

.label-image {
    height: 1.2rem;
    width: 1.2rem;
}

.edit-profile-emoji {
    margin-left: 0.2rem !important;
    cursor: pointer !important;
}

.h-stack {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: center;
}

.v-stack {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: center;
}

.faded-out-text {
    color: rgba(255, 255, 255, 0.5);
}

.talos-green {
    color: rgba(96, 199, 174) !important;
    vertical-align: 0.05rem;
}

.talos-red {
    color: rgba(214, 69, 65) !important;
    vertical-align: 0.05rem;
}

.input-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}

.main-input {
    all: unset;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 1.25rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    justify-content: center;
    gap: 0.2rem;
    width: 100%;
    height: 2.1rem;
}

.drop-badge-rarity-shadow {
    filter: drop-shadow(0 0 0.1rem black);
}