.badge-group {
  display: flex;
  padding: 1rem;
  border-radius: 1rem;
}

.badge-group-inner {
  height: 1rem;
  align-content: center;
  padding-left: 1rem;  
  display: flex;
  flex-wrap: wrap;
}