.journey {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  padding: 1.5rem;
  padding-top: 1.2rem;
  border-radius: 1rem;
  position: relative;
}

.journey-badges {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.journey-badges-step {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.journey-badges-step-inner {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.journey p {
  font-size: 1rem;
}

.badge-connector {
  font-weight: bold;
  margin: 0;
  width: 2.2rem;
  text-align: center;
  padding-bottom: 2rem;
}

.journey-badge-rarity {
  height: 1.8rem;
  width: 1.8rem;
  opacity: 1;
}



.journey-badge-rarity-small {
  height: 1.4rem;
  width: 1.4rem;
  opacity: 1;
  padding: 0;
}


.completed .floating-name {
  background: unset;
}

.journey:not(.completed) {
  outline: 0.15rem dashed rgba(0, 0, 0, 1);
}

.completed {
  background: green;
}

.culture-quest.completed {
  background: linear-gradient(45deg, rgba(229, 190, 155, 0.8) 0%, rgba(200, 140, 90, 0.8) 100%);
}

.engineer-dynamics.completed {
  background: linear-gradient(45deg, rgba(34, 43, 99, 0.8) 0%, rgba(110, 54, 229, 0.8) 100%);
}

.host-of-fun.completed {
  background: linear-gradient(45deg, rgba(40, 170, 40, 0.8) 0%, rgba(0, 200, 150, 0.8) 100%);
}

.crowd-favorite.completed {
  background: linear-gradient(45deg, rgba(255, 223, 93, 0.8) 0%, rgba(255, 196, 0, 0.8) 100%);
}

.talos-influencer.completed {
  background: linear-gradient(45deg, rgba(68, 0, 102, 0.8) 0%, rgba(186, 0, 186, 0.8) 100%);
}

.growth-gardener.completed {
  background: linear-gradient(45deg, rgba(100, 70, 30, 0.8) 0%, rgba(60, 110, 40, 0.8) 100%);
}

.creative-spark.completed {
  background: linear-gradient(45deg, rgba(255, 102, 178, 0.8) 0%, rgba(255, 133, 51, 0.8) 100%);
}

.master-of-many-skills.completed {
  background: linear-gradient(45deg, rgba(255, 90, 0, 0.8) 0%, rgba(255, 200, 0, 0.8) 100%);
}

.communication-commander.completed {
  background: linear-gradient(45deg, rgba(0, 160, 220, 0.8) 0%, rgba(125, 50, 200, 0.8) 100%);
}

.operations-juggernaut.completed {
  background: linear-gradient(45deg, rgba(110, 0, 0, 0.8) 0%, rgba(40, 20, 20, 0.8) 100%);
}

.talos-trendsetter.completed {
  background: linear-gradient(45deg, rgba(255, 45, 85, 0.8) 0%, rgba(0, 210, 180, 0.8) 100%);
}

.journey-name {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
  letter-spacing: 0.05rem;
}

/* when hovering journey, hide journey-badge-rarity and show journey-completed-emoji */

.journey:hover.completed .journey-badge-rarity {
  display: none;
}

.journey:hover .journey-completed-emoji {
  display: block;
}

.journey-completed-emoji {
  height: 2rem;
  width: 2rem;
  font-size: 2rem !important;
  /* center text inside div */
  display: none;
  justify-content: center;
  align-items: center;
  /* move couple pixels up */
  margin-top: -0.2rem;
  margin-bottom: 0.2rem;
  
}
