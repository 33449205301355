.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Dimmed background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Make sure it's on top of other content */
    color: black;
    width: 100%;
    overflow: hidden;
  }

  h1, h2, h3, p {
    margin: 0;
    text-wrap: balance;
  }
  
  .modal-image {
    max-width: 100%;
    display: block;
    margin-bottom: 10px;
    background: white;
    cursor: auto;
    width: 25rem;
  }

  .main-content {
    background: white;
    padding: 10px;
    overflow: visible;
    text-align: -webkit-center;
    height: min-content;
    position: sticky;
    width: 25rem;
  }

  @media (max-width: 615px) {
    .modal-image {
      min-height: 50dvw;
      min-width: 50dvw;
    }

    .main-content {
      max-width: 75dvw;
    }
  }
  
  .modal-content {
    max-height: 80dvh;
    display: flex;
    text-align: center;
    justify-content: center;
    max-width: calc(100% - 100px);
    border-radius: 8px;
    gap: 8px;
    height: min-content;
    overflow: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }


  .modal-content-mobile {
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    max-width: unset;
  }

  .badge-owners-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
    align-self: flex-start;
    overflow-y: auto;
    max-height: 75vh;
    overflow-x: hidden;
  }

  .badge-owners-container-mobile {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
    align-self: flex-start;
    overflow-y: auto;
    max-height: 75vh;
    overflow-x: hidden;
    align-self: center;
  }
  
  .participant-card {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 8px;
    white-space: nowrap;
    overflow: hidden;
    width: 360px;
    height: 50px;
    cursor: pointer;

    img {
      border-radius: 0px;
    }
   
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
  
  .participant-image {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-right: 10px;
  }

  .participant-badges {
    margin: 0;
    font-size: 16px;
  }
  .profile-name {
    text-overflow: ellipsis;
    display: block;
  }

  .pill {
    padding: 3px 6px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
  }

  .glow-legendary {
    background: rgb(246, 169, 27);
    color: white;
  }

  .glow-mythic {
    background: rgb(133, 62, 199);
    color: white;
  }

  .glow-rare {
    background: rgb(6, 150, 239);
    color: white;
  }

  .glow-common {
    background: rgb(255, 255, 255);
    color: black;
  }

.main-and-journeys {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  overflow-y: auto;
  width: 26.2rem;
}

.journeys-included {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.journeys-included-title {
  color: white;
  text-align: left;
  margin-bottom: 0.3rem;
}

.journey-included {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0.2rem 0.7rem;
  border: 1px solid lightgray;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  height: 3rem;
  gap: 0.5rem;
  cursor: pointer;
}

.journey-name {
  all: unset;
  font-size: 1.3rem;
  font-weight: 500;
}

.journey-image {
  width: 1rem;
  height: 1rem;
  border-radius: 25px;
  margin-right: 10px;
}

.journey-badges {
  margin: 0;
  font-size: 16px;
}

.emphasized-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.4rem;
}
