.collected-badges-container {
    color: white;
    position: relative;
    min-width: calc(100vw - 200px);
    scrollbar-width: none;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filter-buttons-container {
    position: absolute;
    display: flex;
    justify-content: left;
    gap: 0.8rem;
    align-items: center;
    margin-bottom: 0.8rem;
    padding: 0.2rem;
    top: -9.3rem;
    z-index: 1000;
    left: calc(50% + 20rem);
}

.filter-button {
    color: white;
    border: none;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    opacity: 0.5;
    background-color: #111111;
    display: flex;
}

.search-box {
    border-radius: 5px;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    width: 18ch;
    height: 1rem;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.filter-button.selected {
    opacity: 1;
}

.collected-badges-container-mobile {
    width: calc(100vw - 40px);
}

.collected-badges-inner-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    scrollbar-width: none;
    gap: 0.45rem;
    justify-content: center;
    max-width: min(max(calc(100vw - 20rem), 96vw), 2400px);
    border-radius: 0.5rem;
}

.collected-badges-inner-container::-webkit-scrollbar {
    display: none;
}

.collected-badges-badge {
    text-align: center;
    cursor: pointer;
    padding: 3px;
    width: 3rem;
}


.next-badges {
    margin-top: 15px;
    margin-bottom: 30px;
}

.next-badges .title {
   margin-bottom: 10px;
}

.next-badges i {
    font-size: 1rem;
}