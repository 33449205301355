@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.badge {
  opacity: 0;
  text-align: center;
  width: calc(100dvh/5);
  overflow: hidden;
  position: relative;
}

.badge.smaller {
  width: 10rem;
}

.badge-inner {
  position: relative;
}

.fadeIn {
  animation: fadeIn 0.8s var(--delay, 0s) forwards;
}

.dim-display {
  filter: grayscale(100%);
  opacity: 1;
}

.badge-image {
  filter: grayscale(0%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100dvh/5);
  height: calc(100dvh/5);
  opacity: 0.45;
  transition: opacity 0.1s;
  cursor: default;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 1);
}

.badge-image.smaller {
  width: 10rem;
  height: 10rem;
}

.border-radius {
  border-radius: 8px;
}

.badge-image:hover:not(.disable-interaction) {
  cursor: pointer;
}

.badge-image:hover:not(.dim):not(.disable-interaction):not(.cursor-not-allowed) {
  opacity: 1;
}

.disable-interaction {
  cursor: not-allowed;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.display {
  opacity: 1;
}

.profile-header {
  position: relative;
  width: 100%;
  margin: 0;
  display: block;
  white-space: nowrap;
}

.profile-header-wrapper {
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.profile-name,
.profile-email {
  transition: opacity 0.3s ease;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  height: 1.7rem;
}

.profile-email {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.profile-header:hover .profile-email {
  opacity: 1;
}

.badge {
  position:  relative;
}

.badge-rarity {
  position: absolute;
  top: 0.3rem;
  left: 0.3rem;
  height: 1.5rem;
  width: 1.5rem;
  opacity: 0;
  z-index: 100;
}

.badge:hover .badge-rarity {
  opacity: 1;
}




@keyframes glow-animation-legendary {
    from {
      filter: drop-shadow(0 -6px 8px rgba(255, 255, 0, 0.9));
    }
    to {
      filter: drop-shadow(0 -6px 15px rgba(255, 255, 0, 0.5));
    }
}

@keyframes glow-animation-mythic {
  from {
    filter: drop-shadow(0 -6px 8px rgba(255, 0, 255, 0.9));
  }
  to {
    filter: drop-shadow(0 -6px 15px rgba(255, 0, 255, 0.5));
  }
}

@keyframes glow-animation-rare {
    from {
      filter: drop-shadow(0 -6px 8px rgba(6, 116, 184, 0.9));
    }
    to {
      filter: drop-shadow(0 -6px 15px rgba(6, 150, 239, 0.5));
    }
}

@keyframes glow-animation-common {
    from {
      filter: drop-shadow(0 -6px 8px rgba(255, 255, 255, 0.9));
    }
    to {
      filter: drop-shadow(0 -6px 15px rgba(255, 255, 255, 0.5));
    }
}

.glow-effect-mythic {
  animation: glow-animation-mythic 2s infinite alternate;
}

.glow-effect-legendary {
  animation: glow-animation-legendary 2s infinite alternate;
}

.glow-effect-rare {
  animation: glow-animation-rare 2s infinite alternate;
}

.glow-effect-common {
  animation: glow-animation-common 2s infinite alternate;
}


.badge p {
  padding: 0.4rem 0.1rem 0.3rem 0.1rem;
}

.badge i {
  padding: 0.4rem 0.1rem 0.3rem 0.1rem;
}

.floating-description {
  position: absolute;
  bottom: 0px;
  background: black;
  text-align: center;
  width: calc(100% - 4px);
  padding: 2px;
  cursor: pointer;
  font-size: 0.9rem;
  min-height: 2.6rem;
}


.floating-name {
  height: 2.4rem;
  text-wrap: unset;
}

.black-and-white {
  filter: grayscale(100%);
}

.opacity-lower-transition {
  transition: opacity 4s !important;
  opacity: 0.35;
}

.opacity-higher-transition {
  transition: opacity 4s !important;
  /* opacity: 0.45; */
}

.low-opacity {
  opacity: 0.8;
}

.hidden-name-removed.common {
  animation: shadow-bottom-common 0.6s ease-out forwards;
}

@keyframes shadow-bottom-common {
  0% {
    box-shadow:none;
  }
  100%{
    box-shadow: 0 1px 5px 3px rgba(255, 255, 255, 0.8);
  }
}

.hidden-name-removed.rare {
  animation: shadow-bottom-rare 0.6s ease-out forwards;
}

@keyframes shadow-bottom-rare {
  0% {
    box-shadow: none;
  }
  100%{
    box-shadow: 0 1px 5px 3px rgba(42, 113, 220, 0.9);
  }
}

.hidden-name-removed.legendary {
  animation: shadow-bottom-legendary 0.6s ease-out forwards;
}

@keyframes shadow-bottom-legendary {
  0% {
    box-shadow: none;
  }
  100%{
    box-shadow: 0 1px 5px 3px rgba(255, 170, 42, 0.8);
  }
}


.hidden-name-removed.mythic {
  animation: shadow-bottom-mythic 0.6s ease-out forwards;
}

@keyframes shadow-bottom-mythic {
  0% {
    box-shadow: none;
  }
  100%{
    box-shadow: 0 1px 5px 3px rgba(255, 0, 255, 0.8);
  }
}


.ban-self-claim {
  cursor: not-allowed !important;
}