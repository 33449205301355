@keyframes scroll {
    0% {
      transform: translateX(calc(50% - 50vw));
    }
    100% {
      transform: translateX(calc(-50% + 50vw));
    }
  }
  
  .all-badges-container {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  
  .badges-container {
    display: flex;
    gap: 0;
    white-space: nowrap;
    overflow: hidden;
    animation: scroll 150s linear infinite alternate;
  }
  
  .delay-1 {
    animation: scroll 150s linear infinite alternate-reverse;
  }
  
  .delay-3 {
    animation: scroll 150s linear infinite alternate-reverse;
  }

 