
html, body {
  width: 100%;
  overflow-x: hidden;
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 1rem;
}

* > i {
  font-size: 0.9rem;
  font-weight: 300;
}

.root {
  margin: 0;
  padding: 0;
}

.App {
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
}

* {
  scrollbar-width: none;
}

p {
  margin: 0;
  padding: 0;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: black;
  background-size: 200px auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.loading {
  color: white;
  height: 100dvh;
  width: 100vw;
  align-content: center;
  overflow: hidden;
  flex-direction: row;
}

@keyframes fadeOut {
  0%,80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading.done {
  position: absolute;
  top: calc(50%);
  left: calc(50%);
  transform: translate(-50%, -50%);
  animation: fadeOut 0.8s 0s forwards;
}

.loading p {
  color: rgba(255, 255, 255, 0.75);
}

.loading .spinning {
  margin-top: 0.8rem;
  opacity: 0.75;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinning {
  animation: spin 1s linear infinite;
}

.hide {
  display: none;
}